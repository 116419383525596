import { DataObject, Item, DataHandler, getDataObjectConfigById, getDataObjectById } from 'o365-dataobject';
import { DataObjectFileUpload } from 'o365-fileupload';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import { app, Procedure } from 'o365-modules';
import { FilterObject } from 'o365-filterobject';
import { ref, type Ref } from 'vue';

import type { UploadOptions } from 'o365-fileupload';

import type { AppState } from 'o365.pwa.types.ts';
import type { IRequestOptions, RequestOperation } from 'o365-dataobject';
import type { WhereExpression } from 'o365.pwa.modules.shared.dexie.WhereExpression.ts';

export type WhereExpressionOperator = 'equals' | 'greaterthan' | 'lessthan' | 'beginswith' | 'endswith' | 'contains' | 'contains_exact' | 'full_text' | 'isnull' | 'istrue' | 'inlist' | 'between' | 'like' | 'isblank' | 'notequals' | 'numbernotequals' | 'greaterthanorequal' | 'lessthanorequal' | 'notbeginswith' | 'notendswith' | 'notcontains' | 'isnotnull' | 'isfalse' | 'notinlist' | 'notbetween' | 'dateequals' | 'datebetween' | 'datenotequals' | 'datenotbetween' | 'dategreaterthan' | 'dategreaterthanorequal' | 'datelessthan' | 'datelessthanorequal' | 'timebetween' | 'timeequals' | 'timebefore' | 'timeafter' | 'notlike' | 'isnotblank';

export interface IWhereGroup {
    type: 'group';
    mode: 'and' | 'or';
    items: Array<WhereObject>,
}

export interface IWhereExpression {
    type: 'expression'
    operator: WhereExpressionOperator,
    column: string,
    valueType?: 'date' | 'datetime' | 'string',
    value: any,
}

export type WhereObject = IWhereGroup | IWhereExpression;

declare module 'o365-dataobject' {
    export interface DataObject {
        _shouldEnableOffline: boolean;
        shouldEnableOffline: boolean;
        _shouldGenerateOfflineData: boolean;
        shouldGenerateOfflineData: boolean;
        _jsonDataVersion: number;
        jsonDataVersion: number;
        _appIdOverride?: string;
        appIdOverride?: string;
        _databaseIdOverride?: string;
        databaseIdOverride?: string;
        _objectStoreIdOverride?: string;
        objectStoreIdOverride?: string;
        _offline: DataObjectOffline;
        offline: DataObjectOffline;
        enableOffline: () => DataObjectOffline;
        _whereObject: WhereObject;
        whereObject: WhereObject;
        _indexedDbWhereExpression: WhereExpression;
        indexedDbWhereExpression: WhereExpression;
    }
    export interface Item {
        getFilePath: (mode: 'view' | 'download' | 'view-pdf' | 'download-pdf', options?: {
            viewName?: string,
            primKey?: string,
            primKeyColumnName?: string,
            fileName?: string,
            fileNameColumnName?: string,
            queryString?: string,
        }) => string;
    }
}

declare module 'o365-modules' {
    export interface IDataObjectConfig {
        enableOffline: boolean;
        generateOfflineData: boolean;
        jsonDataVersion: number;
        appIdOverride?: string;
        databaseIdOverride?: string;
        objectStoreIdOverride?: string;
    }

    export interface IDataObjectFieldConfig {
        pwaIsPrimaryKey?: boolean;
        pwaUseIndex?: boolean;
        pwaIsUnique?: boolean;
        pwaIsMultiValue?: boolean;
        pwaCompoundId?: number;
    }
}

declare module 'o365-fileupload' {
    export interface IFileUpload {
    }
    export interface IChunkUpload {

    }
}

declare module 'o365-filterobject' {
    export interface IFilterObject {
    }
}

type DataObjectOfflineProperties = {
    shouldEnableOffline: boolean;
    offline: DataObjectOffline;
}

export interface IOfflineRequestOptions {
    skipAbortCheck?: boolean,
    appStateOverride?: AppState
}

export interface IDefaultOfflineRequestOptions {
    skipAbortCheck: boolean,
}

export interface IIndexedDBIndexConfig {
    id: string;
    keyPath: string | Array<string> | null;
    isPrimaryKey: boolean;
    isUnique: boolean;
    isMultiEntry: boolean;
    isAutoIncrement: boolean;
}

const defaultOfflineRequestOptions: IDefaultOfflineRequestOptions = {
    skipAbortCheck: false,
} as const;

Object.defineProperties(DataObject.prototype, {
    shouldEnableOffline: {
        get: function shouldEnableOffline(this: DataObject & DataObjectOfflineProperties): boolean {
            return this._shouldEnableOffline ??= getDataObjectConfigById(this.id)?.offline.enableOffline ?? false;
        },
        set: function shouldEnableOffline(this: DataObject & DataObjectOfflineProperties, value: boolean): void {
            this._shouldEnableOffline = value;
        }
    },
    shouldGenerateOfflineData: {
        get: function shouldGenerateOfflineData(this: DataObject & DataObjectOfflineProperties): boolean {
            return this._shouldGenerateOfflineData ??= getDataObjectConfigById(this.id)?.offline.generateOfflineData ?? false;
        },
        set: function shouldGenerateOfflineData(this: DataObject & DataObjectOfflineProperties, value: boolean): void {
            this._shouldGenerateOfflineData = value;
        }
    },
    jsonDataVersion: {
        get: function jsonDataVersion(this: DataObject & DataObjectOfflineProperties): number | undefined {
            return this._jsonDataVersion ??= getDataObjectConfigById(this.id)?.offline.jsonDataVersion ?? -1;
        },
        set: function jsonDataVersion(this: DataObject & DataObjectOfflineProperties, value: number): void {
            this._jsonDataVersion = value;
        }
    },
    appIdOverride: {
        get: function appIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._appIdOverride ??= getDataObjectConfigById(this.id)?.offline.appIdOverride;
        },
        set: function appIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._appIdOverride = value;
        }
    },
    databaseIdOverride: {
        get: function databaseIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._databaseIdOverride ??= getDataObjectConfigById(this.id)?.offline.databaseIdOverride;
        },
        set: function databaseIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._databaseIdOverride = value;
        }
    },
    objectStoreIdOverride: {
        get: function objectStoreIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._objectStoreIdOverride ??= getDataObjectConfigById(this.id)?.offline.objectStoreIdOverride;
        },
        set: function objectStoreIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._objectStoreIdOverride = value;
        }
    },
    offline: {
        get: function offline(this: DataObject & DataObjectOfflineProperties): DataObjectOffline | null {
            if (this.shouldEnableOffline === false) {
                return null;
            }

            return this._offline ??= new DataObjectOffline(this);
        }
    },
    enableOffline: {
        value: function enableOffline(this: DataObject & DataObjectOfflineProperties) {
            return this.offline;
        }
    },
    whereObject: {
        get: function whereObject(this: DataObject & DataObjectOfflineProperties): WhereObject {
            return this._whereObject;
        },
        set: function whereObject(this: DataObject & DataObjectOfflineProperties, newValue: WhereObject): void {
            this._whereObject = newValue;
        }
    },
    indexedDbWhereExpression: {
        get: function indexedDbWhereExpression(this: DataObject & DataObjectOfflineProperties): WhereExpression {
            return this._indexedDbWhereExpression;
        },
        set: function indexedDbWhereExpression(this: DataObject & DataObjectOfflineProperties, newValue: WhereExpression): void {
            
            console.log('Settings IndexedDbWhereExpression', newValue)
            this._indexedDbWhereExpression = newValue;
        }
    },
    useGroupedRequests: {
        get: function useGroupedRequests(this: DataObject & DataObjectOfflineProperties): boolean {
            return false;
        }
    }
});

Object.defineProperties(Procedure.prototype, {
    useGroupedRequests: {
        get: function useGroupedRequests(this: Procedure): boolean {
            return false;
        }
    }
});

const originalFileUpload = DataObjectFileUpload.prototype.upload;

Object.defineProperties(DataObjectFileUpload.prototype, {
    upload: {
        get: function upload(this: DataObjectFileUpload): Function {
            return async (pOptions: UploadOptions, pData: any) => {
                const dataObject = this.getDataObject();
                const dataObjectOffline: DataObjectOffline = dataObject.offline;


                const appIdOverride = dataObjectOffline?.appIdOverride ?? app.id;
                const databaseIdOverride = dataObjectOffline?.databaseIdOverride ?? "DEFAULT";
                const objectStoreIdOverride = dataObjectOffline?.objectStoreIdOverride ?? dataObject.id;

                dataObject.fileUpload.fileUpload.skipXhrHeader = true;

                if (dataObjectOffline === null || dataObjectOffline.appStateOverride === 'ONLINE') {
                    dataObject.fileUpload.fileUpload.skipXhrHeader = false;
                }

                pOptions.chunkInitUrl = `/api/file/chunkupload/initiate/${appIdOverride}/${databaseIdOverride}/${objectStoreIdOverride}${pOptions.data && pOptions.data.values && pOptions.data.values.PrimKey?'/'+pOptions.data.values.PrimKey:''}`;
                return originalFileUpload.call(this, pOptions, pData);
            }
        }
    }
});

const originalsetFilterString = FilterObject.prototype.setFilterString;
Object.defineProperties(FilterObject.prototype, {
    setFilterString: {
        get: function setFilterString(this: FilterObject): Function {
            return (filterString: string): void => {
                if (this.dataObject.shouldEnableOffline) {
                    return;
                }
                return originalsetFilterString.call(this, filterString);
            }
        }
    }
})

const originalDataItemGetFilePath = Item.prototype.getFilePath;

Object.defineProperties(Item.prototype, {
    getFilePath: {
        get: function getFilePath(this: Item): Function {
            return (mode: 'view' | 'download' | 'view-pdf' | 'download-pdf', options?: {
                viewName?: string,
                primKey?: string,
                primKeyColumnName?: string,
                fileName?: string,
                fileNameColumnName?: string,
                queryString?: string,
            }): string => {
                const dataObjectId = this.dataObjectId;
                const dataObject = getDataObjectById(dataObjectId, app.id);
                const dataObjectOffline: DataObjectOffline = dataObject.offline;

                if (dataObjectOffline === null || dataObjectOffline.appStateOverride === 'ONLINE') {
                    return originalDataItemGetFilePath.call(this, mode, options);
                }

                const appIdOverride = dataObjectOffline?.appIdOverride;
                const databaseIdOverride = dataObjectOffline?.databaseIdOverride;
                const objectStoreIdOverride = dataObjectOffline?.objectStoreIdOverride;

                let { viewName, primKey, primKeyColumnName, fileName, fileNameColumnName, queryString } = options ?? {};

                viewName ??= getDataObjectById(this.dataObjectId, this.appId).viewName;
                primKey ??= primKeyColumnName ? this.item[primKeyColumnName] : this.item.PrimKey;
                fileName ??= fileNameColumnName ? this.item[fileNameColumnName] : this.item.FileName;

                let basePath = (() => {
                    switch (mode) {
                        case 'download':
                            return '/pwa/api/file/download';
                        case 'download-pdf':
                            return '/pwa/api/download-pdf';
                        case 'view':
                            return '/pwa/api/file/view';
                        case 'view-pdf':
                            return '/pwa/api/view-pdf';
                    }
                })();

                let url = `${basePath}/${appIdOverride ?? app.id}/${databaseIdOverride ?? dataObject.id}/${objectStoreIdOverride ?? viewName}/${primKey}`;

                if (fileName !== undefined && fileName.length > 0) {
                    // TODO: Move filename from path to querystring. Also need this support in o365.pwa.modules.sw.apiRequestOptions.ApiFileRequestOptions.ts
                    url += `/${fileName}?file-name=${encodeURIComponent(fileName)}`;
                }

                if (queryString !== undefined && queryString.length > 0) {
                    url += `${url.includes('?') ? '&' : '?'}${queryString}`;
                }

                return url;
            };
        }
    }
});

const originalSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;

Object.setPrototypeOf(XMLHttpRequest, {
    setRequestHeader: {
        get: function setRequestHeader(this: XMLHttpRequest): Function {
            return (name: string, value: string) => {
                if (name === 'X-O365-XHR') {
                    return;
                }

                originalSetRequestHeader.call(this, name, value);
            };
        }
    }
});

export class DataObjectOffline {
    private dataObject: DataObject;
    private dataHandlerRequest: Function;
    private getOriginalOptions: Function;
    private _hasOfflineChanges: Ref<Boolean> = ref(false);
    public appStateOverride?: AppState = "OFFLINE";

    public readonly shouldGenerateOfflineData: boolean;
    public readonly jsonDataVersion: number;
    public readonly appIdOverride?: string;
    public readonly databaseIdOverride?: string;
    public readonly objectStoreIdOverride?: string;

    get hasOfflineChanges(): Ref<Boolean> {
        return this._hasOfflineChanges;
    }

    get indexedDBIndexes(): Array<IIndexedDBIndexConfig> {
        const dataObjectConfig = getDataObjectConfigById(this.dataObject.id);
        const fields = Array.from(dataObjectConfig?.fields ?? []);

        const indexes = new Array<IIndexedDBIndexConfig>();

        if (this.shouldGenerateOfflineData) {
            indexes.push({
                id: 'PrimKey',
                keyPath: 'PrimKey',
                isUnique: true,
                isAutoIncrement: false,
                isMultiEntry: false,
                isPrimaryKey: true
            }, {
                id: 'O365_Status',
                keyPath: 'O365_Status',
                isUnique: false,
                isAutoIncrement: false,
                isMultiEntry: false,
                isPrimaryKey: false
            });
        }

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (!field.pwaUseIndex) {
                continue;
            }

            const index = <IIndexedDBIndexConfig>{
                id: field.name,
                keyPath: field.name,
                isPrimaryKey: !!field.pwaIsPrimaryKey,
                isAutoIncrement: !!field.pwa,
                isUnique: !!field.pwaIsUnique,
                isMultiEntry: !!field.pwaIsMultiValue
            };

            if (field.pwaCompoundId) {
                const keyPath = index.keyPath as string;

                index.keyPath = [keyPath];

                for (let j = fields.length - 1; j > i; j--) {
                    const field2 = fields[j];

                    if (field.pwaCompoundId === field2.pwaCompoundId) {
                        index.id += field2.name;
                        index.keyPath.push(field2.name);

                        fields.splice(j, 1);
                    }
                }
            }

            indexes.push(index);
        }

        return indexes;
    }

    constructor(dataObject: DataObject) {
        this.dataObject = dataObject;

        this.shouldGenerateOfflineData = dataObject.shouldGenerateOfflineData;
        this.jsonDataVersion = dataObject.jsonDataVersion;
        this.appIdOverride = dataObject.appIdOverride;
        this.databaseIdOverride = dataObject.databaseIdOverride;
        this.objectStoreIdOverride = dataObject.objectStoreIdOverride;
        const dataHandler = dataObject.dataHandler;

        if (!(dataHandler instanceof DataHandler)) {
            throw new Error('At the moment only DataHandler is supported');
        }

        this.dataHandlerRequest = dataHandler.request.bind(dataHandler);
        this.getOriginalOptions = dataObject.recordSource.getOptions.bind(this.dataObject.recordSource);

        dataHandler.request = this.request.bind(this);
        this.dataObject.recordSource.getOptions = this.getOptions.bind(this);

        if (this.shouldGenerateOfflineData) {
            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_PrimKey',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CCTL',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Created',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CreatedBy_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Updated',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_UpdatedBy_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_JsonData',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Type',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_ErrorMessage',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Owner_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_LastCheckIn',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_AppID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_JsonDataVersion',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_ExternalRef',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CreatedBy',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_UpdatedBy',
            });

            if (this.dataObject.fields['FileRef'] ?? false) {
                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileName',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileSize',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileUpdated',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileRef',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'Extension',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'CheckedOut',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'CheckedOutBy_ID',
                });
            }
            this.getOfflineChanges()
        }

    }

    private async request<T extends IRequestOptions>(pType: RequestOperation, pData: T, pHeaders?: Headers, pOptions?: IOfflineRequestOptions) {
        const vHeaders = pHeaders ?? new Headers();
        const vOptions = Object.assign({}, defaultOfflineRequestOptions, pOptions ?? {});

        const idbApp = await IndexedDBHandler.getApp(app.id);
        const idbPwaState = await idbApp?.pwaState;

        if (idbPwaState) {
            const appStateOverride: AppState = vOptions.appStateOverride ?? this.appStateOverride ?? idbPwaState.appState;

            vHeaders.set('O365-App-State-Override', appStateOverride);
        }

        const response = await this.dataHandlerRequest.call(this, pType, pData, vHeaders, pOptions);

        if (pData.operation === "create" || pData.operation === "destroy" || pData.operation === "update") {
            this.getOfflineChanges();
        }

        return response;
    }

    public async getLocalRecordCount() {
        try {
            const dexieInstance = await IndexedDBHandler.getDexieInstance(this.appIdOverride ?? app.id, this.databaseIdOverride ?? "DEFAULT", this.objectStoreIdOverride ?? this.dataObject.id);
            if (!dexieInstance) {
                return;
            }
            let dexieCollection = dexieInstance;
            const recordCount = await dexieCollection.count();

            return recordCount;
        } catch (e) {
            console.error(e);
        }
        return;
    }

    public async getOfflineChanges() {
        try {
            if (!this.shouldGenerateOfflineData) {
                return false;
            }

            let status = "O365_Status";

            const dexieInstance = await IndexedDBHandler.getDexieInstance(this.appIdOverride ?? app.id, this.databaseIdOverride ?? "DEFAULT", this.objectStoreIdOverride ?? this.dataObject.id);
            if (!dexieInstance) {
                return;
            }
            let dexieCollection = dexieInstance;
            let data = await dexieCollection.where(status).anyOf(["UPDATED", "CREATED", "FILE-CREATED", "FILE-UPDATED"]).count();

            this._hasOfflineChanges = ref(data > 0);

            return this._hasOfflineChanges;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private getOptions() {
        const options = this.getOriginalOptions();

        if (options.filterString && options.filterString === this.dataObject.recordSource.filterString) {
            console.warn(`PWA:: DataObject does not support filter string in PWA mode, use filter object. DataObject ID: ${this.dataObject.id}`);
        }

        if (options.whereClause && options.whereClause === this.dataObject.recordSource.whereClause) {
            console.warn(`PWA:: DataObject does not support where clause in PWA mode, use where object. DataObject ID: ${this.dataObject.id}`);
        }

        delete options.filterString;
        delete options.whereClause;
        delete options.masterDetailString;

        options.dataObjectId = this.dataObject.id;
        options.viewName = this.dataObject.viewName;
        options.uniqueTable = this.dataObject.uniqueTable;

        options.appIdOverride = this.appIdOverride;
        options.databaseIdOverride = this.databaseIdOverride;
        options.objectStoreIdOverride = this.objectStoreIdOverride;

        options.filterObject = this.dataObject.filterObject?.filterObject;
        options.whereObject = this.dataObject.whereObject;
        options.indexedDbWhereExpression = this.dataObject.indexedDbWhereExpression;
        options.masterDetailObject = this.dataObject.masterDetails?.getDevexObject();

        if (options.indexedDbWhereExpression) {
            console.log('Using IndexedDbWhereExpression', options.indexedDbWhereExpression)
        }

        return options;
    }

}

export default DataObjectOffline;
